.DropzoneWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}

.Dropzone {
  height: 200px;
  width: 200px;
  max-height: 200px;
  max-width: 200px;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  font-size: 16px;
}

.FileWrapperlist {
  height: auto;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 2;
  font-size: 16px;
}

.Filelist {
  min-height: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 2;
  font-size: 16px;
}

.Highlight {
  background-color: #fff;
  border: 2px dashed rgb(25, 85, 62);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none;
}

.Progress {
  width: 200px;
  padding: 16px;
}
